import React, { useState, useEffect } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import arabic from "react-date-object/calendars/arabic";  // Hijri calendar
import arabic_en from "react-date-object/locales/arabic_en";  // Arabic locale
import ApiCalls from "../ApiList/apiCalls";
import UpdateJson from "../CommonFiles/UpdateJson";
// import "react-multi-date-picker/styles/colors/teal.css"
import moment from "moment";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import multiColors from "react-multi-date-picker/plugins/colors"
import Settings from "react-multi-date-picker/plugins/settings"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { Button } from "rsuite";
import InputIcon from "react-multi-date-picker/components/input_icon"

const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const ApiList = new ApiCalls();  

function MyPlugin({ position }) {
  return <Button>ok {position}!</Button>;
}


export default function HijriCalendar({ flag }) {
  const [value, setValue] = useState([
    new Date(), 
    new Date().setDate(new Date().getDate() - 5),
  ]);
   const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (flag === false) {
      const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
      
      if (userFilterData) {
        setValue([
          new Date(userFilterData.fromDate.split("/").reverse().join("/")), 
          new Date(userFilterData.toDate.split("/").reverse().join("/")),  
        ]);
      }
    }
  }, [flag]);  

 const handleDateChange = (newDate) => {
    if (newDate && Array.isArray(newDate) && newDate.length === 2) {
      const [startDate, endDate] = newDate;

      const gregorianStartDate = startDate.toDate(); 
      const gregorianEndDate = endDate.toDate();
        UpdateJson([
                {
                  key: "fromDate",
                  value: moment(new Date(gregorianStartDate)).format("DD/MM/YYYY"),
                },
                {
                  key: "toDate",
                  value: moment(new Date(gregorianEndDate)).format("DD/MM/YYYY"),
                },
              ]);
      setValue(newDate);
    }
  };

  // const today = new Date();
  // const twoDaysLater = new Date(today);
  // twoDaysLater.setDate(today.getDate() - 3); 
  // console.log("prmissionData",prmissionData.validFrom,prmissionData.validTo);
  // const validDates = [
  //   today, 
  //   twoDaysLater
  // ];
  // console.log("Valid", validDates);
    // const validFrom = new Date(prmissionData.validFrom);
    // const validFrom=prmissionData.validFrom;
    
    // const validTo =prmissionData.validTo;// new Date(prmissionData.validTo);
    // console.log()
    //new Date(prmissionData.validTo)<new Date()?console.log("Hi"):console.log("Bye");
  // Determine the maxDate based on the validTo date
  // const maxDate = (value[1] && new Date(value[1]) < validTo) ? new Date(value[1]) : validTo;
  // const maxDate=new Date(prmissionData.validTo) < new Date()?new Date(prmissionData.validTo): new Date();
  // Determine the minDate based on the validFrom date
  // const minDate = (value[0] && new Date(value[0]) > validFrom) ? new Date(value[0]) : validFrom;
  // console.log(maxDate);
  return (
    <div
     className="from-date-dropdown datePicker"
      style={{
      
        visibility: flag ? "hidden" : "visible", 
        width: flag ? "0px" : "250px", 
      }}
    >
      <DatePicker  className="teal"
        style={{ width: "100%" }}
        value={value}
        onChange={handleDateChange}  
        calendar={arabic}  
        highlightToday={false}
        rangeHover
        editable={false}
        months={1}
        locale={arabic_en} 
        range  
        numberOfMonths={2}
        dateSeparator=" - "
        minDate={new Date(prmissionData.validTo) < new Date() ? new Date(prmissionData.validFrom):new Date()}
        maxDate={new Date(prmissionData.validTo)}
        //  plugins={[
        //   <DatePickerHeader 
        //     position="top" 
        //     size="medium" 
        //   />,
        //   <DatePanel
        //     position={isRTL ? "left" : "right"}
        //     sort="date"
        //     eachDaysInRange={!props.onlyMonthPicker && !props.onlyYearPicker}
        //   />
        //   multiColors({ position: isRTL ? "right" : "left" }),
        //   <Settings 
        //     position="bottom" 
        //     defaultActive="locale" 
        //   />,
        //   <Toolbar 
        //     position="bottom" 
        //   />
        // <Footer position="bottom" />
    // ]}
    // plugins={[<MyPlugin position="bottom"/>]} 
    render={<InputIcon/>}
      />
    </div>
  );
}
