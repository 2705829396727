import React, { Component } from "react";
import { connect } from "react-redux";
import flyerDetailBackButton from "../../images/flyerDetailBackButton.svg";
import {
  flyerDetailGridView,
  updateFlyerDetail,
} from "../../store/actions/flyersAction";
import FlyerDetailGridView from "./FlyerDetailGridView";
import {
  getFlyerDetailsSuccess,
  updateFlyerDetailsCurrentPage,
} from "../../store/actions/flyerDetailAction";
import ImageSliderCarousel from "./ImageSliderCarousel";
import { updateNavView } from "../../store/actions/filterFormDataAction";
import ApiCalls from "../ApiList/apiCalls";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const ApiList = new ApiCalls();
class FlyerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentIndex: this.props.currentPageNumber
      //   ? this.props.currentPageNumber
      //   : 0,
      currentIndex:0,
        pages:[],
    };
  }

  handleChange = (next, previous) => {
      this.setState({ currentIndex: next });
      this.props.updateFlyerDetailsCurrentPage(next);
    //this.setState({ currentIndex: this.state.pages[next] });
    //this.props.updateFlyerDetailsCurrentPage(this.state.pages[next]);
  };
    handleGridView = (next) => {
      //debugger;
      let index =this.state.pages.indexOf(next);
        this.setState({ currentIndex: index });
    //this.setState({ currentIndex: next+1 });
  };
  componentDidMount() {
    document.removeEventListener("pageOnFLyer", this.onPageChange);
    document.addEventListener("pageOnFLyer", this.onPageChange.bind(this));
    if (JSON.stringify(this.props.flyerDetail) !== "{}") {
      var ar = [];
      ar.push(
        this.props.flyerDetail?.flyerDisplayObj?.flyerPagesList.map((item) => {
          return item.PageNo;
        })
      );
      
      //this.setState({ currentIndex :ar[0]});
      // updateFlyerDetailsCurrentPage(0);
    }
  }
  onPageChange=(ev)=>{
  this.setState({ pages: ev.detail.pageList });
   //this.setState({ currentIndex: ev.detail.pageList[0] });
  }
  render() {
    const {
      gridView,
      updateFlyerDetail,
      flyerDetailGridView,
      flyerDetail,
      currentPageNumber,
      getFlyerDetailsSuccess,
      updateFlyerDetailsCurrentPage,
    } = this.props;
    //console.log(this.state.currentIndex,currentPageNumber);
    if (JSON.stringify(flyerDetail) !== "{}") {
      const imagesList = [];
      imagesList.push(
        flyerDetail.flyerDisplayObj.flyerPagesList.map((item) => {
          return ApiList.getFlyerImageUrl() + item.FlyerImage;
        })
      );
      //console.log("this.state.currentIndex + 1", this.state.currentIndex);
      return (
        <section className="flyer-detail-main-section">
          <div className="flyer-detail-top-section">
            <button
              className="flyer-detail-back-button"
              onClick={() => {
                updateFlyerDetail(false);
                getFlyerDetailsSuccess({});
                this.props.handelState();
              }}
            >
              <img
                src={flyerDetailBackButton}
                className="flyer-page-back-button-icon"
                alt=""
              />
              Back
            </button>
            <div className="flyer-detail-heading-para-container">
              <p className="flyer-detail-heading-para">
                {prmissionData && prmissionData.isAllowOfferLogs ? (
                  <span>
                    {flyerDetail.flyerDisplayObj.flyerDetailsbyId.Name_en +
                      " " +
                      flyerDetail.flyerDisplayObj.flyerDetailsbyId.Id}
                  </span>
                ) : (
                  <span>
                    {flyerDetail.flyerDisplayObj.flyerDetailsbyId.Name_en}
                  </span>
                )}
              </p>
              <p className="flyer-detail-sub-heading-para">
                <span style={{paddingRight:"5px"}}>
                Valid from
                </span>
                {flyerDetail.flyerDisplayObj.flyerDetailsbyId.FlyerStartDate.split(
                  "T"
                )[0]
                  .split("-")
                  .reverse()
                  .join("/")} <span style={{paddingRight:"5px"}}>
                to
                  </span>
                 {flyerDetail.flyerDisplayObj.flyerDetailsbyId.FlyerEndDate.split(
                  "T"
                )[0]
                  .split("-")
                  .reverse()
                  .join("/")}
              </p>
            </div>
            {!gridView ? (
              <button
                className="flyer-detail-grid-view-button"
                onClick={() => {
                  flyerDetailGridView(true);
                }}
              >
                Grid View
              </button>
            ) : (
              <button
                className="flyer-detail-grid-view-button"
                onClick={async () => {
                  flyerDetailGridView(false);
                  await updateFlyerDetailsCurrentPage(currentPageNumber);
                }}
              >
                List View
              </button>
            )}
          </div>

          {!gridView ? (
            <section className="flyer-detail-list-view-container">
              <div className="main-image-container">
                <ImageSliderCarousel
                  flyerPagesList={flyerDetail.flyerDisplayObj.flyerPagesList}
                  updateCurrentPage={updateFlyerDetailsCurrentPage}
                  currentPageNumber={currentPageNumber}
                  handleChange={this.handleChange}
                  currentIndex={this.state.currentIndex}
                  /*currentIndex={this.state.currentIndex-1}*/
                />
              </div>
              <span className="page-number-span">
                {`Page ${this.state.pages[this.state.currentIndex]}/${
                  flyerDetail.flyerDisplayObj?.flyerPagesList[0].TotalPages
                }`}
                {/*{`Page ${this.state.pages[this.state.currentIndex]}/${flyerDetail?.flyerDisplayObj?.flyerPagesList[0].TotalPages}`}*/}
              </span>
            </section>
          ) : (
            <FlyerDetailGridView
              flyerDetail={flyerDetail.flyerDisplayObj.flyerDetailsbyId}
              handleGridView={this.handleGridView}
              flyerPagesList={flyerDetail.flyerDisplayObj.flyerPagesList}
              updateCurrentPage={updateFlyerDetailsCurrentPage}
              flyerDetailGridView={flyerDetailGridView}
            />
          )}
        </section>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    flyersPage: state.flyersPage.flyersPageRender,
    gridView: state.flyersPage.flyerDetailGridView,
    flyerDetail: state.flyerDetail.flyerDetails,
    currentPageNumber: state.flyerDetail.currentPageNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFlyerDetail: (newValue) => dispatch(updateFlyerDetail(newValue)),
    flyerDetailGridView: (newValue) => dispatch(flyerDetailGridView(newValue)),
    updateFlyerDetailsCurrentPage: (currentPageNumber) =>
      dispatch(updateFlyerDetailsCurrentPage(currentPageNumber)),
    updateNavView: (count) => dispatch(updateNavView(count)),
    getFlyerDetailsSuccess: (count) => dispatch(getFlyerDetailsSuccess(count)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FlyerDetail);
