import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./RefineFilter.css";
import SaveCurrentFilter from "../SavedFilter/SaveCurrentFilter";
import UpdateJson from "../CommonFiles/UpdateJson";
import { CheckPicker, CheckTreePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { withSnackbar } from "notistack";
import ApiCalls from "../ApiList/apiCalls";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

function valuetext(value) {
  return `${value}`;
}

const footerStyles = {
  borderTop: "1px solid #e5e5e5",
};
const ApiList = new ApiCalls();

class RefineFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      product: [],
      packsize: [],
      promoType: [],
      attribute: [],
      selecetdBrands: [],
      selecetdProduct: [],
      selecetdPacksize: [],
      selecetdPromoType: [],
      selecetdAttribute: [],
      discountValue: [],
      priceValue: [],
      minMaxPriceValue: [],
      minMaxDiscountValue: [],
      priceFormMinValue: 0.0,
      priceFormMaxValue: 0.0,
      discountFormMinValue: 0.0,
      discountFormMaxValue: 0.0,
      changeInPriceRange: false,
      changeInDiscountRange: false,
      allBrandIds: [],
      allProductIds: [],
      allPacksizeIds: [],
      allPromoTypeIds: [],
      allAttributeIds: [],
      excludeAttribute:false,
      theme: [],
      selectedThemes: [],
      allThemesIds: [],
      excludeTheme: false,
    };
  }
  componentDidMount() {
    document.removeEventListener("offerdetails", this.onStateChange);
    document.addEventListener("offerdetails", this.onStateChange.bind(this));
    document.removeEventListener("newsearch", this.onSearchChange);
    document.addEventListener("newsearch", this.onSearchChange.bind(this));
    document.removeEventListener("clear", this.Change);
    document.addEventListener("clear", this.Change.bind(this));
  }

  onSearchChange() {
    UpdateJson([
      { key: "brandIds", value: "" },
      {
        key: "saveSearchId",
        value: "0",
      },
      {
        key: "searchName",
        value: "String",
      },
      {
        key: "promoPriceMin",
        value: 0.0,
      },
      {
        key: "promoPriceMax",
        value: 0.0,
      },
      {
        key: "discountMin",
        value: 0.0,
      },
      {
        key: "discountMax",
        value: 0.0,
      },
      {
        key: "saveSearchId",
        value: "0",
      },
      {
        key: "searchName",
        value: "String",
      },
      { key: "isAttributeExclude", value: false },
      { key: "excludeTheme", value: false },
      {
        key: "offerValidity",
        value: "",
      },
    ]);
    this.setState({ selecetdAttribute: [] });
    this.setState({ selectedThemes: [] });
    this.setState({ selecetdBrands: [] });
    this.setState({ selecetdProduct: [] });
    this.setState({ selecetdPacksize: [] });
    this.setState({ selecetdPromoType: [] });
    UpdateJson([]);
  }
  Change(ev) {
    this.setState({ selecetdBrands: [] });
  }
  onStateChange(ev) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (ev.detail.source == "brands") {
      if (!userFilterData.brandIds) {
        this.setState({ brands: ev.detail.brand });
        this.setState({
          allBrandIds: ev.detail?.brand?.map((item) => Number(item.id)),
        });
      }
    } else {
      if (userFilterData.brandIds) {
        let filterSelItem = [];
        const id = userFilterData.brandIds;
        var isbid = id.split(",");
        let arrSelItem = ev.detail.brand;
        filterSelItem = arrSelItem
          .filter((item) => isbid.includes(item.id.toString()))
          .map((item) => item.id);
        this.setState({ selecetdBrands: filterSelItem });
      }
      this.setState({
        allBrandIds: ev.detail.brand.map((item) => Number(item.id)),
      });
      this.setState({ brands: ev.detail.brand });
    }
    if (ev.detail.source == "product") {
      if (!userFilterData.product) {
        this.setState({ product: ev.detail.product });
        this.setState({
          allProductIds: ev.detail.product.map((item) => Number(item.id)),
        });
      }
    } else {
      if (userFilterData.product) {
        let filterSelItem = [];
        const id = userFilterData.product;
        var isbid = id.split(",");
        let arrSelItem = ev.detail.product;
        filterSelItem = arrSelItem
          .filter((item) => isbid.includes(item.id.toString()))
          .map((item) => item.id);
        this.setState({ selecetdProduct: filterSelItem });
      }
      this.setState({
        allProductIds: ev.detail.product.map((item) => Number(item.id)),
      });
      this.setState({ product: ev.detail.product });
    }

    if (ev.detail.source == "packsize") {
      if (!userFilterData.packSize) {
        this.setState({ packsize: ev.detail.packsize });
        this.setState({
          allPacksizeIds: ev.detail.packsize.map((item) => Number(item.id)),
        });
      }
    } else {
      if (userFilterData.packSize) {
        let filterSelItem = [];
        const id = userFilterData.packSize;
        var isbid = id.split(",");
        let arrSelItem = ev.detail.packsize;
        filterSelItem = arrSelItem
          .filter((item) => isbid.includes(item.id.toString()))
          .map((item) => item.id);
        this.setState({ selecetdPacksize: filterSelItem });
      }
      this.setState({ packsize: ev.detail.packsize });
      this.setState({
        allPacksizeIds: ev.detail.packsize.map((item) => Number(item.id)),
      });
    }

    if (ev.detail.source == "prompType") {
      if (!userFilterData.promoMechIds) {
        this.setState({ promoType: ev.detail.promoType });
        this.setState({
          allPromoTypeIds: ev.detail.promoType.map((item) => Number(item.id)),
        });
      }
    } else {
      if (userFilterData.promoMechIds) {
        let filterSelItem = [];
        const id = userFilterData.promoMechIds;
        var isbid = id.split(",");
        let arrSelItem = ev.detail.promoType;
        filterSelItem = arrSelItem
          .filter((item) => isbid.includes(item.id.toString()))
          .map((item) => item.id);
        this.setState({ selecetdPromoType: filterSelItem });
      }
      this.setState({
        allPromoTypeIds: ev.detail.promoType.map((item) => Number(item.id)),
      });
      this.setState({ promoType: ev.detail.promoType });
    }
    ///

    if (ev.detail.source == "attribute") {
      if (!userFilterData.attribute) {
        this.setState({ attribute: ev.detail.attribute });
        this.setState({
          allAttributeIds: ev.detail.attribute.map((item) => Number(item.id)),
        });
      }
    } else {
      let flag=false;
      if (userFilterData.attribute) {
        let filterSelItem = [];
        const id = userFilterData.attribute;
        var isbid = id.split(",");
        let arrSelItem = ev.detail.attribute;
        if(arrSelItem.length > 0){
          flag=true;
        filterSelItem = arrSelItem
          .filter((item) => isbid.includes(item.id.toString()))
          .map((item) => item.id);
        this.setState({ selecetdAttribute: filterSelItem });
        }
      }
      if(!flag && !userFilterData.isAttributeExclude){
      this.setState({
        allAttributeIds: ev.detail.attribute.map((item) => Number(item.id)),
      });
      this.setState({ attribute: ev.detail.attribute });
    }
    }

     if (ev.detail.source == "theme") {
      if (!userFilterData.theme) {
        this.setState({ theme: ev.detail.theme });
        this.setState({
          allThemesIds: ev.detail.theme.map((item) => Number(item.id)),
        });
      }
    } else {
      let flag=false;
      if (userFilterData.theme) {
        let filterSelItem = [];
        const id = userFilterData.theme;
        var isbid = id.split(",");
        let arrSelItem = ev.detail.theme;
        if(arrSelItem.length > 0){
          flag=true;
        filterSelItem = arrSelItem
          .filter((item) => isbid.includes(item.id.toString()))
          .map((item) => item.id);
        this.setState({ selectedThemes: filterSelItem });
        }
      }
         if (!flag && !userFilterData.themeExclude) {
             this.setState({
                 allThemesIds: ev.detail.theme.map((item) => Number(item.id)),
             });
             this.setState({ theme: ev.detail.theme });
         } else {
             if (userFilterData.themeExclude) {
                //  this.setState({excludeTheme: true});
                //  this.setState({ theme: ev.detail.theme });
             } else {
                 this.setState({
                     allThemesIds: ev.detail.theme.map((item) => Number(item.id)),
                 });
                 this.setState({ theme: ev.detail.theme });
             }
         }
    }
    if (ev.detail.source == "item") {
      this.setState({ selecetdBrands: [] });
      this.setState({ selecetdProduct: [] });
      this.setState({ selecetdPromoType: [] });
      this.setState({ selecetdPacksize: [] });
      this.setState({ selecetdAttribute: [] });
      this.setState({ selectedThemes: [] });
    }
    if (
      ev.detail.source != "page" &&
      ev.detail.source != "priceRange" &&
      ev.detail.source != "discountRange" &&
      ev.detail.source != "sort" &&
      ev.detail.source != "duplicate" &&
      ev.detail.source != "entry"
    ) {
      this.setState({
        priceValue: ev.detail.offerPriceRange,
        priceFormMinValue: ev.detail.offerPriceRange[0],
        priceFormMaxValue: ev.detail.offerPriceRange[1],
      });
    }
    if (
      ev.detail.source != "page" &&
      ev.detail.source != "priceRange" &&
      ev.detail.source != "discountRange" &&
      ev.detail.source != "sort" &&
      ev.detail.source != "duplicate" &&
      ev.detail.source != "entry"
    ) {
      this.setState({
        minMaxPriceValue: ev.detail.offerPriceRange,
        priceFormMinValue: ev.detail.offerPriceRange[0],
        priceFormMaxValue: ev.detail.offerPriceRange[1],
      });
    }
    if (
      ev.detail.source != "page" &&
      ev.detail.source != "discountRange" &&
      //ev.detail.source != "priceRange" &&
      ev.detail.source != "sort" &&
      ev.detail.source != "duplicate" &&
      ev.detail.source != "entry"
    ) {
      this.setState({
        discountValue: ev.detail.discountPriceRange,
        discountFormMinValue: ev.detail.discountPriceRange[0],
        discountFormMaxValue: ev.detail.discountPriceRange[1],
      });
    }
    if (
      ev.detail.source != "page" &&
      ev.detail.source != "discountRange" &&
      //ev.detail.source != "priceRange" &&
      ev.detail.source != "sort" &&
      ev.detail.source != "duplicate" &&
      ev.detail.source != "entry"
    ) {
      this.setState({
        minMaxDiscountValue: ev.detail.discountPriceRange,
        discountFormMinValue: ev.detail.discountPriceRange[0],
        discountFormMaxValue: ev.detail.discountPriceRange[1],
      });
    }
  }

  handleDiscountSubmit = async (event) => {
    event.preventDefault();
    if (
      parseFloat(this.state.discountFormMinValue) <=
      parseFloat(this.state.discountFormMaxValue)
    ) {
      UpdateJson([
        {
          key: "discountMin",
          value: parseFloat(this.state.discountFormMinValue),
        },
        {
          key: "discountMax",
          value: parseFloat(this.state.discountFormMaxValue),
        },
      ]);
      this.props.getOfferByUserPermission("discountRange");
    } else {
      const message = `Please select a valid discount range.`;
      this.props.enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  handlePriceSubmit = (event) => {
    event.preventDefault();
    if (
      parseFloat(this.state.priceFormMinValue) <=
      parseFloat(this.state.priceFormMaxValue)
    ) {
      UpdateJson([
        {
          key: "promoPriceMin",
          value: parseFloat(this.state.priceFormMinValue),
        },
        {
          key: "promoPriceMax",
          value: parseFloat(this.state.priceFormMaxValue),
        },
      ]);
      this.props.getOfferByUserPermission("priceRange");
    } else {
      const message = `Please select a valid price range.`;
      this.props.enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };
  handleBrandCheckAll = (value, checked) => {
    if (checked == true) {
      UpdateJson([{ key: "brandIds", value: "" }]);
      this.props.getOfferByUserPermission("brands");
    }
    this.setState({
      selecetdBrands: checked ? this.state.allBrandIds : [],
    });
    UpdateJson([{ key: "brandIds", value: "" }]);
  };
  handleProductCheckAll = (value, checked) => {
    if (checked == true) {
      UpdateJson([{ key: "product", value: "" }]);
      this.props.getOfferByUserPermission("product");
    }
    this.setState({
      selecetdProduct: checked ? this.state.allProductIds : [],
    });
    UpdateJson([{ key: "product", value: "" }]);
  };
  handlePackSizeCheckAll = (value, checked) => {
    if (checked == true) {
      UpdateJson([{ key: "packSize", value: "" }]);
      this.props.getOfferByUserPermission("packsize");
    }
    this.setState({
      selecetdPacksize: checked ? this.state.allPacksizeIds : [],
    });
    UpdateJson([{ key: "packSize", value: "" }]);
  };
  handlePromoTypeCheckAll = (value, checked) => {
    if (checked == true) {
      UpdateJson([{ key: "promoMechIds", value: "" }]);
      this.props.getOfferByUserPermission("prompType");
    }
    this.setState({
      selecetdPromoType: checked ? this.state.allPromoTypeIds : [],
    });
    UpdateJson([{ key: "promoMechIds", value: "" }]);
  };
  handleAttributeCheckAll = (value, checked) => {
    if (checked == true) {
      UpdateJson([{ key: "attribute", value: "" }]);
      this.props.getOfferByUserPermission("attribute");
    }
    this.setState({
      selecetdAttribute: checked ? this.state.allAttributeIds : [],
    });
    UpdateJson([{ key: "attribute", value: "" }]);
  };

  handleThemeCheckAll = (value, checked) => {
    if (checked == true) {
      UpdateJson([{ key: "theme", value: "" }]);
      this.props.getOfferByUserPermission("theme");
    }
    this.setState({
      selectedThemes: checked ? this.state.allThemesIds : [],
    });
    UpdateJson([{ key: "theme", value: "" }]);
  };

  handleExclude=(event)=>{
       this.setState((prevState) => ({
         excludeAttribute: event.target.checked,
       }));
        UpdateJson([
          { key: "isAttributeExclude", value: event.target.checked },
        ]);
         this.props.getOfferByUserPermission("attribute");
  }

  handleExcludeTheme=(event)=>{
       this.setState((prevState) => ({
         excludeTheme: event.target.checked,
       }));
        UpdateJson([
          { key: "themeExclude", value: event.target.checked },
        ]);
         this.props.getOfferByUserPermission("theme");
  }

  render() {
    return (
      <section className="refine-filter-top-section">
        <section className="refine-filter-main-section">
          {prmissionData && prmissionData.isAllowBrand ? (
            <CheckPicker
              style={{ width: "100%" }}
              data={this.state.brands}
              sticky
              className="refine-select"
              onChange={(value) => {
                this.setState({ selecetdBrands: value });
                UpdateJson([
                  { key: "brandIds", value: value.toString() },
                  {
                    key: "pageNo",
                    value: 1,
                  },
                ]);
                this.props.getOfferByUserPermission("brands");
              }}
              value={this.state.selecetdBrands}
              placeholder="Brand"
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      this.state.selecetdBrands.length > 0 &&
                      this.state.selecetdBrands.length <
                        this.state.allBrandIds.length
                    }
                    checked={
                      this.state.selecetdBrands.length > 0 &&
                      this.state.selecetdBrands.length ===
                        this.state.allBrandIds.length
                    }
                    onChange={this.handleBrandCheckAll}
                  ></Checkbox>
                  <span>Check all</span>
                </div>
              )}
              loading={false}
              labelKey="nameEn"
              valueKey="id"
              virtualized
              limit={0}
            />
          ) : null}
          {prmissionData && prmissionData.isRefineProduct ? (
            <CheckPicker
              style={{ width: "100%" }}
              data={this.state.product}
              sticky
              className="refine-select"
              onChange={(value) => {
                this.setState({ selecetdProduct: value });
                UpdateJson([
                  { key: "product", value: value.toString() },
                  {
                    key: "pageNo",
                    value: 1,
                  },
                  { key: "attribute", value: "" },
                ]);
                 if (this.state.selecetdProduct.length != 1) {
                   UpdateJson([{ key: "attribute", value: "" }]);
                 }
                this.props.getOfferByUserPermission("product");
              }}
              value={this.state.selecetdProduct}
              placeholder="Product"
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      this.state.selecetdProduct.length > 0 &&
                      this.state.selecetdProduct.length <
                        this.state.allProductIds.length
                    }
                    checked={
                      this.state.selecetdProduct.length > 0 &&
                      this.state.selecetdProduct.length ===
                        this.state.allProductIds.length
                    }
                    onChange={this.handleProductCheckAll}
                  ></Checkbox>
                  <span>Check all</span>
                </div>
              )}
              loading={false}
              labelKey="nameEn"
              valueKey="id"
              virtualized
              limit={0}
            />
          ) : null}
          {prmissionData && prmissionData.isAllowPackSize ? (
            <CheckPicker
              style={{ width: "100%" }}
              data={this.state.packsize}
              sticky
              className="refine-select"
              onChange={(value) => {
                this.setState({ selecetdPacksize: value });
                UpdateJson([
                  { key: "packSize", value: value.toString() },
                  {
                    key: "pageNo",
                    value: 1,
                  },
                ]);
                this.props.getOfferByUserPermission("packsize");
              }}
              value={this.state.selecetdPacksize}
              placeholder="PackSize"
              virtualized
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      this.state.selecetdPacksize.length > 0 &&
                      this.state.selecetdPacksize.length <
                        this.state.allPacksizeIds.length
                    }
                    checked={
                      this.state.selecetdPacksize.length > 0 &&
                      this.state.selecetdPacksize.length ===
                        this.state.allPacksizeIds.length
                    }
                    onChange={this.handlePackSizeCheckAll}
                  ></Checkbox>
                  <span>Check all</span>
                </div>
              )}
              loading={false}
              labelKey="nameEn"
              valueKey="id"
              limit={0}
            />
          ) : null}
          {prmissionData && prmissionData.isRefinePromomechanic ? (
            <CheckPicker
              style={{ width: "100%" }}
              data={this.state.promoType}
              sticky
              className="refine-select"
              onChange={(value) => {
                this.setState({ selecetdPromoType: value });
                UpdateJson([
                  { key: "promoMechIds", value: value.toString() },
                  {
                    key: "pageNo",
                    value: 1,
                  },
                ]);
                this.props.getOfferByUserPermission("prompType");
              }}
              value={this.state.selecetdPromoType}
              placeholder="PromoType"
              virtualized
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  <Checkbox
                    indeterminate={
                      this.state.selecetdPromoType.length > 0 &&
                      this.state.selecetdPromoType.length <
                        this.state.allPromoTypeIds.length
                    }
                    checked={
                      this.state.selecetdPromoType.length > 0 &&
                      this.state.selecetdPromoType.length ===
                        this.state.allPromoTypeIds.length
                    }
                    onChange={this.handlePromoTypeCheckAll}
                  ></Checkbox>
                  <span>Check all</span>
                </div>
              )}
              loading={false}
              labelKey="nameEn"
              valueKey="id"
              limit={0}
            />
          ) : null}
                {prmissionData &&
                    prmissionData.isAllowAttribute &&
                    this.state.selecetdProduct.length > 0 ? (
                    // (this.state.selecetdProduct.length === 1 && this.state.attribute.length > 0) ? (
                    <CheckPicker
                        style={{ width: "100%" }}
                        data={this.state.attribute}
                        sticky
                        className="refine-select"
                        virtualized
                        onChange={(value) => {
                            this.setState({ selecetdAttribute: value });
                            UpdateJson([
                                { key: "attribute", value: value.toString() },
                                {
                                    key: "pageNo",
                                    value: 1,
                                },
                            ]);
                            this.props.getOfferByUserPermission("attribute");
                        }}
                        value={this.state.selecetdAttribute}
                        placeholder="Attribute"
                        renderExtraFooter={() => (
                            <div>
                                <div style={footerStyles}>
                                    <Checkbox
                                        indeterminate={
                                            this.state.selecetdAttribute.length > 0 &&
                                            this.state.selecetdAttribute.length <
                                            this.state.allAttributeIds.length
                                        }
                                        checked={
                                            this.state.selecetdAttribute.length > 0 &&
                                            this.state.selecetdAttribute.length ===
                                            this.state.allAttributeIds.length
                                        }
                                        onChange={this.handleAttributeCheckAll}
                                    ></Checkbox>
                                    <span>Check all</span>
                                </div>
                                <div
                                    style={{
                                        borderTop: "1px solid #e5e5e5",
                                    }}
                                >
                                    <Checkbox
                                        checked={this.state.excludeAttribute}
                                        onChange={this.handleExclude}
                                        disabled={this.state.selecetdAttribute.length >= 1 ? false : true}
                                    ></Checkbox>
                                    <span>Exclude</span>
                                </div>
                            </div>
                        )}
                        loading={false}
                        labelKey="nameEn"
                        valueKey="id"
                        limit={0}
                    />
                ) : null}  
              {prmissionData && prmissionData.isRefineFlyerTheme ? (
                  <CheckPicker
                        style={{ width: "100%" }}
                        data={this.state.theme}
                        sticky
                        className="refine-select"
                        virtualized
                        onChange={(value) => {
                            this.setState({ selectedThemes: value });
                            UpdateJson([
                                { key: "theme", value: value.toString() },
                                {
                                    key: "pageNo",
                                    value: 1,
                                },
                            ]);
                            this.props.getOfferByUserPermission("theme");
                        }}
                        value={this.state.selectedThemes}
                        placeholder="Flyer Themes"
                        renderExtraFooter={() => (
                            <div>
                                <div style={footerStyles}>
                                    <Checkbox
                                        indeterminate={
                                            this.state.selectedThemes.length > 0 &&
                                            this.state.selectedThemes.length <
                                            this.state.allThemesIds.length
                                        }
                                        checked={
                                            this.state.selectedThemes.length > 0 &&
                                            this.state.selectedThemes.length ===
                                            this.state.allThemesIds.length
                                        }
                                        disabled={this.state.theme.length == 0}
                                        onChange={this.handleThemeCheckAll}
                                    ></Checkbox>
                                    <span>Check all</span>
                                </div>
                                <div
                                    style={{
                                        borderTop: "1px solid #e5e5e5",
                                    }}
                                >
                                    <Checkbox
                                        checked={this.state.excludeTheme}
                                        onChange={this.handleExcludeTheme}
                                        disabled={this.state.selectedThemes.length >= 1 ? false : true}
                                    ></Checkbox>
                                    <span>Exclude</span>
                                </div>
                            </div>
                        )}
                        loading={false}
                        labelKey="nameEn"
                        valueKey="id"
                        limit={0}
                    />
              ) : null}


          {/* <CheckTreePicker
            data={data}
            value={value}
            onChange={(newValue) => setValue(newValue)}
          /> */}
          {/* <CheckTreePicker
            style={{ width: "100%" }}
            data={data}
            sticky
            className="refine-select"
            onChange={(value) => {
              console.log(value);
              //this.setState({ selecetdAttribute: value });
              // UpdateJson([
              //   { key: "attribute", value: value.toString() },
              //   {
              //     key: "pageNo",
              //     value: 1,
              //   },
              // ]);
              // this.props.getOfferByUserPermission("attribute");
            }}
            //value={this.state.selecetdAttribute}
            placeholder="Attribute"
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    this.state.selecetdAttribute.length > 0 &&
                    this.state.selecetdAttribute.length <
                      this.state.allAttributeIds.length
                  }
                  checked={
                    this.state.selecetdAttribute.length > 0 &&
                    this.state.selecetdAttribute.length ===
                      this.state.allAttributeIds.length
                  }
                  // onChange={this.handleAttributeCheckAll}
                ></Checkbox>
                <span>Check all</span>
              </div>
            )}
            loading={false}
            //labelKey="nameEn"
            //valueKey="id"
            limit={0}
          /> */}
          {prmissionData && prmissionData.isAllowPriceSlider ? (
            <div className="price-slider-container">
              <span
                style={{
                  width: "70px",
                  height: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "18px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                  color: "#757474",
                }}
              >
                Offer Price
              </span>

              <div className="price-range-slider-div">
                <Box className="price-range-slider-box">
                  <Slider
                    sx={{
                      width: 146,
                      "& .MuiSlider-thumb": {
                        color: "#0070C0",
                        boxShadow:
                          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                      },
                      "& .MuiSlider-valueLabelOpen": {
                        fontSize: "13px",
                        top: "-5px",
                      },
                    }}
                    size="small"
                    getAriaLabel={() => "Offer Price"}
                    min={this.state.minMaxPriceValue[0]}
                    max={this.state.minMaxPriceValue[1]}
                    value={[
                      this.state.priceFormMinValue,
                      this.state.priceFormMaxValue,
                    ]}
                    onChange={async (event, value) => {
                      this.setState({ priceValue: value });
                      this.setState({ changeInPriceRange: true });
                      this.setState({
                        priceFormMinValue: Number(value[0]),
                        priceFormMaxValue: Number(value[1]),
                      });
                      UpdateJson([
                        {
                          key: "promoPriceMin",
                          value: Number(this.state.priceFormMinValue),
                        },
                        {
                          key: "promoPriceMax",
                          value: Number(this.state.priceFormMaxValue),
                        },
                        {
                          key: "pageNo",
                          value: 1,
                        },
                      ]);
                    }}
                    getAriaValueText={valuetext}
                    disableSwap
                  />
                </Box>
                <form onSubmit={this.handlePriceSubmit}>
                  <div className="slider-form-section">
                    <input
                      onChange={(event) => {
                        const { value } = event.target;
                        this.setState({ changeInPriceRange: true });
                        this.setState({ priceFormMinValue: parseFloat(value) });
                      }}
                      className="price-start-range"
                      type="number"
                      name="priceFormMinValue"
                      value={this.state.priceFormMinValue}
                      min="0.0"
                      step="0.1"
                    />
                    <span className="price-slider-span">to</span>
                    <input
                      onChange={(event) => {
                        const { value } = event.target;
                        this.setState({ changeInPriceRange: true });
                        this.setState({ priceFormMaxValue: parseFloat(value) });
                      }}
                      value={this.state.priceFormMaxValue}
                      min="0.0"
                      step="0.1"
                      className="price-end-range"
                      type="number"
                      name="priceFormMaxValue"
                    />
                  </div>
                  <div className="slider-apply-button-div">
                    <button type="submit" className="price-range-apply-button">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          {prmissionData && prmissionData.isAllowDiscountSlider ? (
            <div className="discount-slider-container">
              <span
                style={{
                  height: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "18px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0.15px",
                  color: "#757474",
                }}
              >
                {/* <span style={{ marginRight: "4%" }}>%</span> */}
                Discount
              </span>

              <div className="discount-range-slider-div">
                <Box className="discount-range-slider-box">
                  <Slider
                    sx={{
                      width: 146,
                      "& .MuiSlider-thumb": {
                        color: "#0070C0",
                        boxShadow:
                          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                      },
                      "& .MuiSlider-valueLabelOpen": {
                        fontSize: "13px",
                        top: "-5px",
                      },
                    }}
                    size="small"
                    getAriaLabel={() => "Discount Range"}
                    min={this.state.minMaxDiscountValue[0]}
                    max={this.state.minMaxDiscountValue[1]}
                    value={[
                      this.state.discountFormMinValue,
                      this.state.discountFormMaxValue,
                    ]}
                    onChange={async (event, value) => {
                      this.setState({ changeInDiscountRange: true });
                      this.setState({ discountValue: value });
                      this.setState({
                        discountFormMinValue: value[0],
                        discountFormMaxValue: value[1],
                      });
                      UpdateJson([
                        {
                          key: "discountMin",
                          value: parseFloat(this.state.discountFormMinValue),
                        },
                        {
                          key: "discountMax",
                          value: parseFloat(this.state.discountFormMaxValue),
                        },
                        {
                          key: "pageNo",
                          value: 1,
                        },
                      ]);
                    }}
                    getAriaValueText={valuetext}
                    disableSwap
                  />
                </Box>
                <form onSubmit={this.handleDiscountSubmit}>
                  <div className="discount-from-section">
                    <input
                      className="discount-start-range"
                      type="number"
                      name="discountFormMinValue"
                      value={this.state.discountFormMinValue}
                      onChange={(event) => {
                        const { value } = event.target;
                        this.setState({ changeInDiscountRange: true });
                        this.setState({
                          discountFormMinValue: parseFloat(value),
                        });
                      }}
                      min="0.0"
                      step="0.1"
                    />
                    <span
                      style={{
                        borderBottom: "1px solid #AAA",
                        height: "30px",
                        borderBottomRightRadius: "5px",
                        fontSize: "14px",
                        lineHeight: "18px",
                        paddingTop: "8px",
                      }}
                    >
                      %
                    </span>
                    <span className="discount-slider-span">to</span>
                    <input
                      className="discount-end-range"
                      type="number"
                      name="discountFormMaxValue"
                      value={this.state.discountFormMaxValue}
                      onChange={(event) => {
                        this.setState({ changeInDiscountRange: true });
                        const { value } = event.target;
                        this.setState({
                          discountFormMaxValue: parseFloat(value),
                        });
                      }}
                      min="0.0"
                      step="0.1"
                    />
                    <span
                      style={{
                        borderBottom: "1px solid #AAA",
                        height: "30px",
                        borderBottomRightRadius: "5px",
                        fontSize: "14px",
                        lineHeight: "18px",
                        paddingTop: "8px",
                      }}
                    >
                      %
                    </span>
                  </div>
                  {/* <span>%</span> */}
                  <div className="slider-apply-button-div">
                    <button
                      className="discount-range-apply-button"
                      type="submit"
                    >
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          <section className="saved-filter-section">
            {prmissionData && prmissionData.isAllowSaveSearch ? (
              <SaveCurrentFilter
                changeInPriceRange={this.state.changeInPriceRange}
                changeInDiscountRange={this.state.changeInDiscountRange}
                value={this.props.value}
                changeValue={this.props.changeValue}
              />
            ) : null}
          </section>
        </section>
        {/* <a
          href="http://52.164.214.188:91/Retailer/Retailer/OfferBank?ids=1"
          target="_blank"
        > */}
        {/* {prmissionData && prmissionData.isSwitchDisplayBtn ? (
          <button
            className="switch-to-classic-mode-button"
            onClick={this.handleClick}
          >
            <span className="switch-to-classic-mode-text">
              Switch to Classic Mode
            </span>
          </button>
        ) : null} */}
        {/* </a> */}
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: (src) =>
      dispatch(getOfferByUserPermission(src, true)),
  };
};
export default connect(null, mapDispatchToProps)(withSnackbar(RefineFilter));
