import React, { Component } from "react";
import { connect } from "react-redux";
import { getSearchSuggestionData } from "../../store/actions/searchSuggestionAction";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import "../../custom.css";
import UpdateJson from "../CommonFiles/UpdateJson";
import moment from "moment";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typing: false,
      typingTimeout: 0,
      val: "",
      passingVal: "",
      updateValue: "",
      suggestions: [],
      selctedVal: {},
      isflyer: false,
      isEnter: false,
    };
    this.onTest = this.onTest.bind(this);
  }
  componentDidMount() {
    if (window.location.pathname == "/flyers") this.setState({ isflyer: true });
    document.addEventListener("clear", this.onStateChange.bind(this));
    document.addEventListener("newsearch", this.onSearchChange.bind(this));
    //document.addEventListener("country", this.onCountryChange.bind(this));
    document.addEventListener(
      "searchSuggestionData",
      this.onSuggestionData.bind(this)
    );
  }

  onSuggestionData(ev) {
    const suggestionData = ev.detail.searchSuggestionData;
    let tempArr = [];
    if (suggestionData?.saveSearcSuggetions?.length > 0)
      suggestionData.saveSearcSuggetions.map((item) => {
        tempArr.push(item);
      });
    else suggestionData.saveSearcSuggetions = [];

    if (suggestionData?.itemSuggestionModels?.length > 0)
      suggestionData.itemSuggestionModels.map((item) => tempArr.push(item));
    else suggestionData.itemSuggestionModels = [];

    if (suggestionData?.brandSuggestions?.length > 0)
      suggestionData.brandSuggestions.map((item) => tempArr.push(item));
    else suggestionData.brandSuggestions = [];
    if (tempArr.length > 1) {
      this.setState({ suggestions: tempArr });
    }
  }

  // onCountryChange(ev) {
  //   UpdateJson([
  //     { key: "analyticsSearchText", value: "" },
  //     { key: "suggestionText", value: "" },
  //   ]);
  //   this.setState({ val: "" });
  // }

  onSearchChange(ev) {
    var tempArr = new Array();
    tempArr.push(
      { key: "brandIds", value: "" },
      { key: "product", value: "" },
      { key: "packSize", value: "" },
      { key: "promoMechIds", value: "" },
      { key: "attribute", value: "" },
      { key: "theme", value: "" },
      { key: "analyticsSearchText", value: "" },
      { key: "suggestionText", value: "" }
    );
    UpdateJson(tempArr);
    this.setState({ updateValue: "" });
    this.setState({ val: "" });
    this.setState({ passingVal: "" });
  }

  onStateChange(ev) {
    this.setState({ val: "" });
    this.setState({ passingVal: "" });
    this.setState({ updateValue: "" });
    this.setState({ suggestions: [] });
    var tempArr = new Array();
    tempArr.push(
      { key: "analyticsSearchText", value: "" },
      { key: "suggestionText", value: "" },
      { key: "brandIds", value: "" },
      { key: "product", value: "" },
      { key: "packSize", value: "" },
      { key: "promoMechIds", value: "" },
      { key: "attribute", value: "" },
      { key: "theme", value: "" }
    );
    UpdateJson(tempArr);
  }
  onTest = (e) => {
    var tempArr = new Array();
    tempArr.push(
      { key: "brandIds", value: "" },
      { key: "categoryIds", value: "" },
      { key: "subCategoryIds", value: "" },
      { key: "itemIds", value: "" },
      {
        key: "promoPriceMin",
        value: 0.0,
      },
      {
        key: "promoPriceMax",
        value: 0.0,
      },
      {
        key: "discountMin",
        value: 0.0,
      },
      {
        key: "discountMax",
        value: 0.0,
      },
      { key: "product", value: "" },
      { key: "packSize", value: "" },
      { key: "promoMechIds", value: "" },
      { key: "attribute", value: "" },
      { key: "theme", value: "" },
      { key: "analyticsSearchText", value: e.target.value },
      { key: "suggestionText", value: e.target.value }
    );
    UpdateJson(tempArr);
    this.props.getOfferByUserPermission(this.props.formData, "item");
    const customEvent = new CustomEvent("srchBarChange", {
      detail: {
        flag: "entre",
      },
    });
    document.dispatchEvent(customEvent);
    this.setState({ suggestions: [] });
  };

  changeSearch = (value) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typing: false,
      typingTimeout: setTimeout(() => {
        if (!this.state.isEnter) {
          this.props.getSearchSuggestionData(value);
        }
      }, 250),
    });
  };

  getOptionLabel = (value) => {
    return "";
  };
  render() {
    return (
      <div
        style={{
          visibility: this.state.isflyer ? "hidden" : "visible",
          width: this.state.isflyer ? "0px" : "100%",
          maxWidth: "340px",
        }}
      >
        <Autocomplete
          sx={{
            width: "100%",
            backgroundColor: "#FFF",
            borderRadius: "4px",

            "& .MuiInputBase-root": {
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "22px",
              letterSpacing: "0.46px",
              color: "#AAAAAA",
            },
            "& .MuiInputBase-root": {
              height: "30px",
            },

            "input#free-solo-2-demo": {
              padding: "6px 0px 6px 6px",
            },
          }}
          className="srchBarPadding"
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          value={this.state.val}
          filterOptions={(x) => x}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.setState({ isEnter: true }, () => {
                this.onTest(e);
              });
            }
          }}
          options={this.state.suggestions}
          getOptionLabel={(option) => option.nameEn || this.state.updateValue}
          onChange={async (event, value) => {
            UpdateJson([
              {
                key: "promoPriceMin",
                value: 0.0,
              },
              {
                key: "promoPriceMax",
                value: 0.0,
              },
              {
                key: "discountMin",
                value: 0.0,
              },
              {
                key: "discountMax",
                value: 0.0,
              },
            ]);
            let flag = value.type;
            {
              if (flag == "Items") {
                var tempArr = new Array();
                tempArr.push(
                  { key: "brandIds", value: "" },
                  { key: "product", value: "" },
                  { key: "packSize", value: "" },
                  { key: "promoMechIds", value: "" },
                  { key: "attribute", value: "" },
                  { key: "theme", value: "" },
                  { key: "analyticsSearchText", value: "" },
                  { key: "suggestionText", value: "" },
                  { key: "categoryIds", value: value.categoryId },
                  { key: "subCategoryIds", value: value.subCategoryId },
                  { key: "itemIds", value: value.itemId }
                );
                UpdateJson(tempArr);
                this.props.getOfferByUserPermission("item");
                const customEvent = new CustomEvent("srchBarChange", {
                  detail: {
                    value: value.nameEn,
                    flag: "item",
                  },
                  //src: "item",
                });
                document.dispatchEvent(customEvent);
              } else if (flag == "Brands") {
                var tempArr = new Array();
                tempArr.push(
                  { key: "brandIds", value: value.brandId },
                  { key: "categoryIds", value: value.categoryId },
                  { key: "subCategoryIds", value: value.subCategoryId },
                  { key: "product", value: "" },
                  { key: "packSize", value: "" },
                  { key: "promoMechIds", value: "" },
                  { key: "attribute", value: "" },
                  { key: "theme", value: "" },
                  { key: "itemIds", value: "" },
                  { key: "analyticsSearchText", value: "" },
                  { key: "suggestionText", value: "" }
                );
                UpdateJson(tempArr);
                this.props.getOfferByUserPermission(
                  this.props.formData,
                  "search"
                );
                const customEvent = new CustomEvent("srchBarChange", {
                  detail: {
                    value: value.nameEn,
                    flag: "brand",
                  },
                });
                document.dispatchEvent(customEvent);
              } else {
                UpdateJson([
                  { key: "brandIds", value: "" },
                  { key: "product", value: "" },
                  { key: "packSize", value: "" },
                  { key: "promoMechIds", value: "" },
                  { key: "attribute", value: "" },
                  { key: "theme", value: "" },
                  { key: "categoryIds", value: "" },
                  { key: "subCategoryIds", value: "" },
                  { key: "itemIds", value: "" },
                  { key: "analyticsSearchText", value: "" },
                  { key: "suggestionText", value: "" },
                ]);
                if (value.strSearchFromDate) {
                  UpdateJson([
                    {
                      key: "fromDate",
                      value: moment(new Date(value.strSearchFromDate)).format(
                        "DD/MM/YYYY"
                      ),
                    },
                  ]);
                }
                if (value.strSearchToDate) {
                  UpdateJson([
                    {
                      key: "toDate",
                      value: moment(new Date(value.strSearchToDate)).format(
                        "DD/MM/YYYY"
                      ),
                    },
                  ]);
                }
                // if (value.CountryIdsJson){
                //  UpdateJson([
                //    { key: "countryId", value: value.CountryIdsJson },
                //  ]);
                // }
                if (value.cityIdsJson)
                  UpdateJson([{ key: "cityIds", value: value.cityIdsJson }]);
                if (value.retailerIdsJson)
                  UpdateJson([
                    { key: "retailerIds", value: value.retailerIdsJson },
                  ]);
                if (value.categoryIdsJson)
                  UpdateJson([
                    { key: "categoryIds", value: value.categoryIdsJson },
                  ]);
                if (value.subCategoryIdsJson)
                  UpdateJson([
                    { key: "subCategoryIds", value: value.subCategoryIdsJson },
                  ]);
                if (value.itemIdsJson)
                  UpdateJson([{ key: "itemIds", value: value.itemIdsJson }]);
                if (value.brandIdsJson)
                  UpdateJson([{ key: "brandIds", value: value.brandIdsJson }]);
                if (value.productIdsJson)
                  UpdateJson([{ key: "product", value: value.productIdsJson }]);
                if (value.packSizeJson)
                  UpdateJson([{ key: "packSize", value: value.packSizeJson }]);
                if (value.promoMachenicJson)
                  UpdateJson([
                    { key: "promoMechIds", value: value.promoMachenicJson },
                  ]);

                if (value.searchName)
                  UpdateJson([{ key: "searchName", value: value.searchName }]);

                if (value.attributeIdsJson)
                  UpdateJson([
                    { key: "attribute", value: value.attributeIdsJson },
                  ]);

                if (value.discountRangeFrom > 0) {
                  UpdateJson([
                    { key: "discountMax", value: value.discountRangeFrom },
                    { key: "discountMin", value: value.discountRangeTo },
                  ]);
                }

                if (value.priceRangeFrom > 0) {
                  UpdateJson([
                    { key: "promoPriceMin", value: value.priceRangeFrom },
                    { key: "promoPriceMax", value: value.priceRangeTo },
                  ]);
                }

                this.props.getOfferByUserPermission(
                  this.props.formData,
                  "main"
                );
                // const savedData = {
                //   id: value.Id,
                //   name: value.SearchName,
                //   desc: value.Description,
                //   flag: 1,
                // };
                //localStorage.setItem("savedflag", JSON.stringify(savedData));
                const customEvent = new CustomEvent("srchBarChange", {
                  detail: {
                    // value: value.Name_en,
                    flag: "savedSearch",
                  },
                  //src: "item",
                });
                document.dispatchEvent(customEvent);
                // const customEvent = new CustomEvent("srchBarChange");
                // document.dispatchEvent(customEvent);
                // const savedEvent = new CustomEvent("savedfilter", {
                //   detail: {
                //     id: value.Id,
                //     name: value.SearchName,
                //     desc: value.Description,
                //     flag: 1,
                //   },
                // });
                // document.dispatchEvent(savedEvent);
              }
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img loading="lazy" width="20" src={option.image} alt="" />
              {option.nameEn}
            </Box>
          )}
          renderInput={(params) => (
            <>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                  },
                }}
                onChange={(event) => {
                  this.setState({ isEnter: false });
                  this.setState({
                    updateValue: event.target.value,
                  });
                  if (event.target.value.length >= 3) {
                    this.changeSearch(event.target.value);
                    this.setState({
                      val: event.target.value,
                      passingVal: event.target.value,
                    });
                  } else if (event.target.value.length > 2) {
                    this.setState({ val: "", passingVal: "" });
                    UpdateJson([
                      { key: "analyticsSearchText", value: "" },
                      { key: "suggestionText", value: "" },
                    ]);
                  } else {
                    this.setState({ suggestions: [] });
                  }
                }}
                {...params}
                placeholder="&#x1F50E;&#xFE0E; Search by Item and Brand..."
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            </>
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: (src) =>
      dispatch(getOfferByUserPermission(src, true)),
    getSearchSuggestionData: (newValue) => {
      dispatch(getSearchSuggestionData(newValue));
    },
  };
};

export default connect(null, mapDispatchToProps)(SearchBar);
