import React, { useEffect, useState } from 'react';
import { CheckTreePicker, Button, Container } from 'rsuite';

// const data=[
//   {
//     "name": "Region 1",
//     "citiesIds": "1",
//     "children": [
//       { "name": "City A", "citiesIds": "1A" },
//       { "name": "City B", "citiesIds": "1B" }
//     ]
//   },
//   {
//     "name": "Region 2",
//     "citiesIds": "2",
//     "children": [
//       { "name": "City C", "citiesIds": "2C" },
//       { "name": "City D", "citiesIds": "2D" }
//     ]
//   }
// ]


function CheckTreePickerExample() {
  const [selectedItems, setSelectedItems] = useState([]);
   const [data, setData] = useState([]); // Use state to store region list data

  const handleChange = (value) => {
     setSelectedItems(value);
  };

  useEffect(() => {
    // Fetch data from localStorage
    const listData = JSON.parse(localStorage.getItem("listData"));
    if (listData && listData.regionList) {
       setData(listData.regionList); // Set the data state
    }
  }, []); // Only run once on component mount

  const handleSubmit = () => {
  };


  return (
    <Container style={{ padding: 20 }}>
      <CheckTreePicker
        data={data} // Data fetched from localStorage
        value={selectedItems} // Bind selected items to state
        onChange={handleChange} // Update state on change
        placeholder="Select items"
        style={{ width: 300 }}
        labelKey="name" // Assuming 'name' is the field you want to display
        valueKey="value" // Assuming 'citiesIds' is the field for selection
      />
    </Container>
  );
}

export default CheckTreePickerExample;
