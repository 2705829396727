import React, { Component } from "react";
import { connect } from "react-redux";
import { getDataByPermission } from "../../store/actions/getDataByPermissionActions";
import {
  getOfferByUserPermission,
  toggleInitialize,
} from "../../store/actions/offerImageActions";
import ApiCalls from "../ApiList/apiCalls";
import {
  clearFlyerPage,
  flyersPageRender,
} from "../../store/actions/flyersAction";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";
import "./MainFilter.css";
import Loader from "../CommonFiles/Loader";
import SearchBar from "../Navbar/SearchBar";
import UpdateJson from "../CommonFiles/UpdateJson";
import { CheckPicker, Checkbox } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  updatePage,
  updateSavedFilterCount,
} from "../../store/actions/userManagerAction";
import BrandFilter from "../Flyers/BrandFilter";

const userData = JSON.parse(localStorage.getItem("userData"));
const userFormData = JSON.parse(localStorage.getItem("userFormData"));
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const ApiList = new ApiCalls();

const footerStyles = {
  borderTop: "1px solid #e5e5e5",
};

const footerButtonStyle = {
  float: "right",
  marginRight: 10,
  marginTop: 2,
};

class MainFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      selectedCategoryIds: [],
      allCategoryIds: [],
      subCategory: [],
      filterSubCategory: [],
      filterSubCategoryIds: [],
      selectedSubCategoryIds: [],
      allSubCategoryIds: [],
      item: [],
      filterItem: [],
      selectedItemIds: [],
      allItemIds: [],
      filterAllItemIds: [],
      isflyer: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    if (window.location.pathname == "/flyers") this.setState({ isflyer: true });
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.props.flyersPage && this.setState({ loading: false });
    !this.props.flyersPage &&
      this.props.getDataByPermission(userData.userId, userData.isTnCStatus);
    document.removeEventListener("dataOnPermission", this.onPermissionData);
    document.addEventListener(
      "dataOnPermission",
      this.onPermissionData.bind(this)
    );
    this.props.updatePage("offer");
    document.removeEventListener("srchBarChange", this.onSearchChange);
    document.addEventListener("srchBarChange", this.onSearchChange.bind(this));
    document.removeEventListener("offerBankClick", this.logoClick);
    document.addEventListener("offerBankClick", this.logoClick.bind(this));
    document.removeEventListener("savedFilterCount", this.countChnage);
    document.addEventListener("savedFilterCount", this.countChnage.bind(this));
  }
  componentWillUnmount() {
    this.props.updatePage("");
  }
  countChnage(ev) {
    this.props.updateSavedFilterCount(ev.detail.savedOfferCount);
  }
  logoClick() {
    this.props.toggleInitialize(false);
    if (window.location.pathname == "/offers") {
      this.setState({
        selectedCategoryIds: [],
        selectedSubCategoryIds: [],
        selectedItemIds: [],
      });
      UpdateJson([
        { key: "categoryIds", value: "" },
        { key: "subCategoryIds", value: "" },
        { key: "itemIds", value: "" },
      ]);
    }
    // this.setState({
    //   selectedCategoryIds: [],
    //   selectedSubCategoryIds: [],
    //   selectedItemIds: [],
    // });
    UpdateJson([
      // { key: "categoryIds", value: "" },
      // { key: "subCategoryIds", value: "" },
      // { key: "itemIds", value: "" },
      {
        key: "promoPriceMin",
        value: 0.0,
      },
      {
        key: "promoPriceMax",
        value: 0.0,
      },
      {
        key: "discountMin",
        value: 0.0,
      },
      {
        key: "discountMax",
        value: 0.0,
      },
    ]);
  }
  onPermissionData(ev) {
    this.setState({ category: ev.detail.categoryList });
    this.setState({ subCategory: ev.detail.subCategoryList });
    this.setState({ filterSubCategory: ev.detail.subCategoryList });
    this.setState({ filterItem: ev.detail.itemList });
    this.setState(
      { item: ev.detail.itemList },
      this.backButtonLogic.bind(this)
    );
    this.setState({
      allCategoryIds: ev.detail.categoryList
        ? ev.detail.categoryList.map((item) => Number(item.categoryId))
        : [],
    });
    this.setState({
      allSubCategoryIds: ev.detail.subCategoryList
        ? ev.detail.subCategoryList.map((item) => Number(item.subCategoryId))
        : [],
    });
    this.setState({
      allItemIds: ev.detail.itemList
        ? ev.detail.itemList.map((item) => Number(item.itemId))
        : [],
    });
    this.setState({ loading: false });
  }

  onSearchChange(ev) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    let selectedCategory = [];
    let selectedSubCategory = [];
    let selectedItem = [];
    if (ev.detail.flag === "item" || ev.detail.flag === "brand") {
      // const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
      this.setState({
        selectedCategoryIds: [],
        selectedSubCategoryIds: [],
        selectedItemIds: [],
      });
      if (userFilterData.categoryIds) {
        let id = userFilterData.categoryIds;
        var stringCategoryId = id.split(",");
        selectedCategory = stringCategoryId.map((item) => Number(item));
        this.onCategoryChange(selectedCategory);
        this.setState({ selectedCategoryIds: selectedCategory });
      }
      if (userFilterData.subCategoryIds) {
        let subIds = userFilterData.subCategoryIds;
        var stringCategoryId = subIds.split(",");
        selectedSubCategory = stringCategoryId.map((item) => Number(item));
        this.onSubCategoryChange(selectedSubCategory);
        this.setState({ selectedSubCategoryIds: selectedSubCategory });
      }
      if (userFilterData.itemIds) {
        let itemId = userFilterData.itemIds;
        var stringItemId = itemId.split(",");
        if (ev.detail.flag == "brand" && itemId == "") {
          this.setState({ selectedItemIds: this.state.allItemIds });
        } else {
          selectedItem = stringItemId.map((item) => Number(item));
          this.setState({ selectedItemIds: selectedItem });
          this.onItemChange(selectedItem);
        }
      }
    } else if (ev.detail.flag === "entre") {
      this.setState({
        selectedCategoryIds: [],
        selectedSubCategoryIds: [],
        selectedItemIds: [],
      });
    } else {
      if (userFilterData.categoryIds) {
        let id = userFilterData.categoryIds;
        var stringCategoryId = id.split(",");
        selectedCategory = stringCategoryId.map((item) => Number(item));
        this.onCategoryChange(selectedCategory);
        this.setState({ selectedCategoryIds: selectedCategory });
      }
      if (userFilterData.subCategoryIds) {
        let subIds = userFilterData.subCategoryIds;
        var stringCategoryId = subIds.split(",");
        selectedSubCategory = stringCategoryId.map((item) => Number(item));
        this.onSubCategoryChange(selectedSubCategory);
        this.setState({ selectedSubCategoryIds: selectedSubCategory });
      }
      if (userFilterData.itemIds) {
        let itemId = userFilterData.itemIds;
        var stringItemId = itemId.split(",");
        if (ev.detail.flag == "brand" && itemId == "") {
          this.setState({ selectedItemIds: this.state.allItemIds });
        } else {
          selectedItem = stringItemId.map((item) => Number(item));
          this.setState({ selectedItemIds: selectedItem });
          this.onItemChange(selectedItem);
        }
      }
    }
  }
  backButtonLogic() {
    const data = ApiList.getUserState();
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const isNotEqal = JSON.stringify(userFilterData) != JSON.stringify(data);
    if (isNotEqal) {
      const customEvent = new CustomEvent("srchBarChange", {
        detail: {
          flag: "item",
        },
      });
      document.dispatchEvent(customEvent);
      this.setState({ loading: false });
      this.props.getOfferByUserPermission("data");
    }
  }
  onCategoryChange = (value) => {
    UpdateJson([{ key: "subCategoryIds", value: "" }]);
    this.setState({ selectedSubCategoryIds: [] });
    this.setState({ filterItem: [] });
    this.setState({ selectedItemIds: [] });
    this.setState({ selectedCategoryIds: value });

    let arrSubCategory = this.state.subCategory;
    let FilterSubCategory = [];
    for (var i = 0; i < value.length; i++) {
      FilterSubCategory = [
        ...FilterSubCategory,
        arrSubCategory.filter((item) => item.categoryId == value[i]),
      ];
    }
    let subCat = [];
    // FilterSubCategory.forEach((element) =>
    //   element.map((item) => subCat.push(item))
    // );
    let selSubCategoryIds = [];
    FilterSubCategory.forEach((element) =>
      element.forEach((item) => {
        subCat.push(item);
        selSubCategoryIds.push(item.subCategoryId);
      })
    );
    this.setState({ filterSubCategoryIds: selSubCategoryIds });
    this.setState({
      filterSubCategory: subCat.length > 0 ? subCat : this.state.subCategory,
    });
    this.onSubCategoryChange(selSubCategoryIds);
    UpdateJson([{ key: "categoryIds", value: value.toString() }]);
  };

  onSubCategoryChange = (value) => {
    this.setState({ selectedItemIds: [] });
    let arrItem = this.state.item;
    let FilterItem = [];
    for (var j = 0; j < value.length; j++) {
      FilterItem = [
        ...FilterItem,
        arrItem.filter((item) => item.subCategoryId == value[j]),
      ];
    }
    let tempIArr = [];
    //FilterItem.forEach((element) => element.map((item) => tempIArr.push(item)));
    let selSubCategoryIds = [];
    FilterItem.forEach((element) =>
      element.forEach((item) => {
        tempIArr.push(item);
        selSubCategoryIds.push(item.itemId);
      })
    );
    this.onItemChange(selSubCategoryIds);

    this.setState({
      filterItem: tempIArr.length > 0 ? tempIArr : this.state.item,
    });

    this.setState({ selectedSubCategoryIds: value });
    UpdateJson([{ key: "subCategoryIds", value: value.toString() }]);
  };

  onItemChange = (value) => {
    this.setState({ selectedItemIds: value });
    UpdateJson([{ key: "itemIds", value: value.toString() }]);
  };

  handleCategoryCheckAll = (value, checked) => {
    this.setState({
      selectedCategoryIds: checked ? this.state.allCategoryIds : [],
    });
    // this.setState({ filterSubCategory: checked ? this.state.subCategory : [] });
    this.setState({
      filterSubCategory: this.state.subCategory,
    });
    UpdateJson([{ key: "categoryIds", value: "" }]);
    this.handleSubCategoryCheckAll("", checked, "category");
  };

  handleSubCategoryCheckAll = (value, checked, flag) => {
    if (flag === "category") {
      this.setState({
        selectedSubCategoryIds: checked
          ? this.state.subCategory.map((element) => {
              return element.subCategoryId;
            })
          : [],
      });
    } else {
      this.setState({
        selectedSubCategoryIds: checked
          ? this.state.filterSubCategory.map((element) => {
              return element.subCategoryId;
            })
          : [],
      });
    }
    //flag=checked? "all":""
    // if (flag.length > 0) {
    //   this.setState({
    //     selectedSubCategoryIds: checked ? this.state.allSubCategoryIds : [],
    //     filterSubCategoryIds: checked ? this.state.allSubCategoryIds : [],
    //   });
    //   //  this.setState({
    //   //    selectedSubCategoryIds: checked ? this.state.filterSubCategoryId : [],
    //   //  });
    // } else {
    //   this.setState({
    //     selectedSubCategoryIds: checked ? this.state.filterSubCategoryId : [],
    //   });
    //   this.setState({
    //     filterSubCategoryId: checked
    //       ? this.state.filterSubCategory.map((item) =>
    //           Number(item.subCategoryId)
    //         )
    //       : [],
    //   });
    // }

    let arrItem = this.state.item;
    let filterSubCategoryId = [];
    if (flag === "category") {
      filterSubCategoryId = this.state.subCategory.map((element) => {
        return element.subCategoryId;
      });
    } else {
      filterSubCategoryId = this.state.filterSubCategory.map((element) => {
        return element.subCategoryId;
      });
    }

    let FilterItem = [];
    for (var j = 0; j < filterSubCategoryId.length; j++) {
      FilterItem = [
        ...FilterItem,
        arrItem.filter((item) => item.subCategoryId == filterSubCategoryId[j]),
      ];
    }
    let tempIArr = [];
    FilterItem.forEach((element) => element.map((item) => tempIArr.push(item)));

    //this.setState({ filterItem: checked ? tempIArr : [] });
    this.setState({ filterItem: tempIArr });
    UpdateJson([{ key: "subCategoryIds", value: "" }]);
    this.handleItemCheckAll("", checked, "subcategory");
  };

  handleItemCheckAll = (value, checked, flag) => {
    // this.setState({
    //   selectedItemIds: checked ? this.state.allItemIds : [],
    // });
    let arrItem = this.state.item;
    let filterSubCategoryId = [];
    if (flag === "subcategory") {
      filterSubCategoryId = this.state.filterSubCategory.map((element) => {
        return element.subCategoryId;
      });
    } else {
      filterSubCategoryId =
        this.state.selectedSubCategoryIds.length > 0
          ? this.state.selectedSubCategoryIds
          : this.state.allSubCategoryIds;
    }
    // filterSubCategoryId = this.state.filterSubCategory.map((element) => {
    //   return element.subCategoryId;
    // });
    let FilterItem = [];
    for (var j = 0; j < filterSubCategoryId.length; j++) {
      FilterItem = [
        ...FilterItem,
        arrItem.filter((item) => item.subCategoryId == filterSubCategoryId[j]),
      ];
    }
    let tempIArr = [];
    FilterItem.forEach((element) => element.map((item) => tempIArr.push(item)));
    if (flag === "subcategory") {
      this.setState({
        selectedItemIds: checked
          ? this.state.item.map((element) => {
              return element.itemId;
            })
          : [],
      });
    } else {
      this.setState({
        selectedItemIds: checked
          ? tempIArr.map((element) => {
              return element.itemId;
            })
          : [],
      });
    }
    UpdateJson([{ key: "itemIds", value: "" }]);
  };

  render() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return (
      <>
        <Loader open={this.state.loading} />
        <section
          style={{
            display: "flex",
            flex: "1 1",
          }}
        >
          <SearchBar />
          <div
            style={{
              visibility: prmissionData?.isValidity ? "visible" : "hidden",
            }}
          >
            <BrandFilter
              flyerFlag={this.state.isflyer}
              isDisable={prmissionData?.isFlyerAudit}
            />
          </div>
          <CheckPicker
            className="select"
            style={{
              maxWidth: this.state.isflyer ? 0 : 120,
              width: "100%",
              marginLeft: "8px",
              visibility: this.state.isflyer ? "hidden" : "visible",
            }}
            virtualized
            data={this.state.category}
            sticky
            onChange={(value) => {
              this.onCategoryChange(value);
            }}
            value={this.state.selectedCategoryIds}
            placeholder="Category"
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    this.state.selectedCategoryIds.length > 0 &&
                    this.state.selectedCategoryIds.length <
                      this.state.allCategoryIds.length
                  }
                  checked={
                    this.state.selectedCategoryIds.length ===
                    this.state.allCategoryIds.length
                  }
                  onChange={this.handleCategoryCheckAll}
                >
                  Check all
                </Checkbox>
              </div>
            )}
            labelKey="name"
            valueKey="categoryId"
            limit={0}
          />
          <CheckPicker
            className="select"
            style={{
              maxWidth: this.state.isflyer
                ? 0
                : prmissionData.isAllowSubCategory
                ? 120
                : 0,
              width: "100%",
              marginLeft: "8px",
              visibility: this.state.isflyer
                ? "hidden"
                : prmissionData.isAllowSubCategory
                ? "visible"
                : "hidden",
            }}
            data={this.state.filterSubCategory}
            sticky
            onChange={(value) => {
              this.onSubCategoryChange(value);
            }}
            value={this.state.selectedSubCategoryIds}
            placeholder="SubCategory"
            virtualized
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    this.state.selectedSubCategoryIds.length > 0 &&
                    this.state.selectedSubCategoryIds.length <
                      this.state.filterSubCategory.length
                  }
                  checked={
                    this.state.selectedSubCategoryIds.length > 0 &&
                    this.state.selectedSubCategoryIds.length ===
                      this.state.filterSubCategory.length
                  }
                  onChange={this.handleSubCategoryCheckAll}
                >
                  Check all
                </Checkbox>
              </div>
            )}
            labelKey="name"
            valueKey="subCategoryId"
            limit={0}
          />
          <CheckPicker
            style={{
              maxWidth: this.state.isflyer
                ? 0
                : prmissionData.isAllowItem
                ? 120
                : 0,
              width: "100%",
              marginLeft: "8px",
              visibility: this.state.isflyer
                ? "hidden"
                : prmissionData.isAllowItem
                ? "visible"
                : "hidden",
            }}
            virtualized
            className="select"
            data={this.state.filterItem}
            sticky
            onChange={(value) => {
              this.onItemChange(value);
            }}
            value={this.state.selectedItemIds}
            placeholder="Item"
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    this.state.selectedItemIds.length > 0 &&
                    this.state.selectedItemIds.length <
                      this.state.filterItem.length
                  }
                  checked={
                    this.state.selectedItemIds.length > 0 &&
                    this.state.selectedItemIds.length ===
                      this.state.filterItem.length
                  }
                  onChange={this.handleItemCheckAll}
                >
                  Check all
                </Checkbox>
              </div>
            )}
            labelKey="name"
            valueKey="itemId"
            limit={0}
          />
          <div
            className="main-filter-sepration-div"
            style={{
              minWidth: this.state.isflyer ? 0 : 36,
              visibility: this.state.isflyer ? "hidden" : "visible",
            }}
          ></div>
          <section className="main-filters-buttons">
            <button
              className="main-filters-clear-all-button"
              style={{
                width: this.state.isflyer ? 0 : 96,
                visibility: this.state.isflyer ? "hidden" : "visible",
              }}
              onClick={async () => {
                const customEvent = new CustomEvent("clear");
                document.dispatchEvent(customEvent);
                {
                  this.state.isflyer
                    ? this.props.toggleInitialize(true)
                    : this.props.toggleInitialize(false);
                }
                {
                  this.state.isflyer && this.props.clearFlyerPage();
                  await this.props.flyersPageRender(this.state.isflyer);
                }
                var tempArr = new Array();
                tempArr.push(
                  { key: "categoryIds", value: "" },
                  { key: "subCategoryIds", value: "" },
                  { key: "itemIds", value: "" },
                  { key: "brandIds", value: "" },
                  { key: "product", value: "" },
                  { key: "packSize", value: "" },
                  { key: "promoMechIds", value: "" },
                  { key: "attribute", value: "" },
                  { key: "theme", value: "" },
                  { key: "analyticsSearchText", value: "" },
                  { key: "suggestionText", value: "" },
                  { key: "pageNo", value: 1 },
                  { key: "isShowDuplicate", value: false },
                  { key: "pageSize", value: 20 },
                  { key: "saveSearchId", value: "0" },
                  { key: "description", value: "" },
                  { key: "searchName", value: "String" },
                  { key: "sorting", value: "N" },
                  { key: "isAttributeExclude", value: false },
                  { key: "themeExclude", value: false },
                  {
                    key: "offerValidity",
                    value: "",
                  }
                );
                UpdateJson(tempArr);
                this.setState({ selectedRetailer: [] });
                this.setState({ selectedCity: [] });
                this.setState({ selectedCategoryIds: [] });
                this.setState({ selectedSubCategoryIds: [] });
                //this.setState({ filterSubCategory: [] });
                //this.setState({ filterItem: [] });
                this.setState({
                  filterSubCategory: this.state.subCategory,
                });
                this.setState({ filterItem: this.state.item });
                this.setState({ selectedItemIds: [] });
              }}
            >
              Clear All
            </button>

            <button
              className="main-filters-search-button"
              onClick={() => {
                if (this.state.isflyer) {
                  this.props.getFlyersPageDataObject();
                  this.props.flyersPageRender(true);
                  this.props.toggleInitialize(true);
                  const customEvent = new CustomEvent("newsearch", {
                    detail: { src: "flyer" },
                  });
                  document.dispatchEvent(customEvent);
                  const customEvent1 = new CustomEvent(
                    "flyerBrandOnChange",
                    {}
                  );
                  document.dispatchEvent(customEvent1);
                } else {
                  const customEvent = new CustomEvent("newsearch");
                  document.dispatchEvent(customEvent);
                  UpdateJson([
                    {
                      key: "pageNo",
                      value: 1,
                    },
                  ]);
                  //  var tempArr = new Array();
                  //  tempArr.push(
                  //    { key: "brandIds", value: "" },
                  //    { key: "product", value: "" },
                  //    { key: "packSize", value: "" },
                  //    { key: "promoMechanic", value: "" },
                  //    { key: "attribute", value: "" },
                  //  );
                  //  UpdateJson(tempArr);
                  this.props.getOfferByUserPermission("main");
                }
              }}
            >
              Apply
            </button>
          </section>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDataByPermission: (userId, isTnC) =>
      dispatch(getDataByPermission(userId, isTnC, true)),
    toggleInitialize: (value) => dispatch(toggleInitialize(value, true)),
    flyersPageRender: (value) => dispatch(flyersPageRender(value)),
    getOfferByUserPermission: (src) =>
      dispatch(getOfferByUserPermission(src, true)),
    getFlyersPageDataObject: () => dispatch(getFlyersPageDataObject(true)),
    clearFlyerPage: () => dispatch(clearFlyerPage()),
    updateSavedFilterCount: (value) => dispatch(updateSavedFilterCount(value)),
    updatePage: (page) => dispatch(updatePage(page)),
  };
};
export default connect(null, mapDispatchToProps)(MainFilters);
